<template>
  <b-card
    :id="`branch-${branch.id}`"
    class="rounded-5 h-100"
  >
    <b-dropdown
      v-if="isSmallScreen && currentBreakPoint !== 'md'"
      id="dropdown-1"
      toggle-class="my-custom-toggle"
      size="sm"
      right
      no-caret
      variant="outline-none"
      class="z-top"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          color="#000"
        />
      </template>
      <b-dropdown-item
        :to="{
          name: 'editBranch',
          params: {
            supplierId: this.$route.params.supplierId,
            branchId: this.$route.params.branchId,
          },
        }"
        variant="primary"
      >
        {{ $t('edit') }}
      </b-dropdown-item>
      <b-dropdown-item
        :to="{
          name: 'shippings',
          params: {
            branchId: this.$route.params.branchId,
          },
        }"
        variant="primary"
      >
        {{ $t('shippings') }}
      </b-dropdown-item>
      <b-dropdown-item
        variant="primary"
        :to="{
          name: 'supplierProductsCatalogs',
          params: { id: branch.id, type: 'sucursal' },
        }"
      >
        {{ $t('ProductCatalogs') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          currentUser.role_name === 'admin' ||
            currentUser.role_name === 'supplier_admin'
        "
        variant="outline-primary"
        :to="{ name: 'supplierQuotes', params: { id: branch.id } }"
      >
        {{ $t('biddings') }}
      </b-dropdown-item>
    </b-dropdown>
    <b-row class="align-items-center">
      <b-col>
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <b-img
              fluid
              width="70px"
              :src="require('@/assets/images/logo/logo.png')"
            />
          </div>
          <div class="w-75">
            <div class="d-flex">
              <h3 class="mb-0 mr-1 font-weight-bolder">
                {{ providerName }} {{ $t('branch') }} {{ branch.name }}
              </h3>
              <!-- <b-form-checkbox
                size="sm"
                :value="branch.is_express"
                name="check-button"
                button
                :button-variant="branch.is_express ? 'success' : 'secondary'"
              >
                {{ branch.is_express ? $t('express') : 'Normal' }}
              </b-form-checkbox> -->
              <div class="d-flex align-items-center">
                <span class="mr-1 text-muted">
                  {{ $t('deliveryTime') }}
                </span>
                <b-overlay :show="expressLoading">
                  <b-form-radio-group
                    id="btn-radios-1"
                    :checked="branch.is_express"
                    size="sm"
                    name="radios-btn-default"
                    buttons
                    @change="changeExpressStatus"
                  >
                    <b-form-radio
                      button-variant="outline-primary"
                      :value="true"
                    >
                      {{ $t('express') }}
                    </b-form-radio>
                    <b-form-radio
                      button-variant="outline-secondary"
                      :value="false"
                    >
                      Normal
                    </b-form-radio>
                  </b-form-radio-group>
                </b-overlay>
              </div>
            </div>
            <span class="text-muted"> {{ branch.email }}</span>
            <div
              v-if="!isSmallScreen || currentBreakPoint === 'md'"
              class="d-flex mt-1"
            >
              <b-button
                size="sm"
                :to="{
                  name: 'editBranch',
                  params: {
                    supplierId: this.$route.params.supplierId,
                    branchId: this.$route.params.branchId,
                  },
                }"
                class="mr-1 d-flex align-items-center"
                variant="primary"
              >
                {{ $t('edit') }}
              </b-button>
              <b-button
                size="sm"
                :to="{
                  name: 'shippings',
                  params: {
                    branchId: this.$route.params.branchId,
                  },
                }"
                class="mr-1 d-flex align-items-center"
                variant="primary"
              >
                {{ $t('shippings') }}
              </b-button>
              <b-button
                size="sm"
                class="mr-1 d-flex align-items-center"
                variant="primary"
                :to="{
                  name: 'supplierProductsCatalogs',
                  params: { id: branch.id, type: 'sucursal' },
                }"
              >
                {{ $t('ProductCatalogs') }}
              </b-button>
              <!-- <b-button
                size="sm"
                variant="outline-danger"
              >
                {{ $t('delete') }}
              </b-button> -->
              <b-button
                v-if="
                  currentUser.role_name === 'admin' ||
                    currentUser.role_name === 'supplier_admin'
                "
                size="sm"
                class="d-flex align-items-center"
                variant="outline-primary"
                :to="{ name: 'supplierQuotes', params: { id: branch.id } }"
              >
                {{ $t('biddings') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        md="auto"
        class="mt-1 mt-lg-0"
      >
        <b-row>
          <b-col sm="auto">
            <div class="d-flex align-items-center">
              <feather-icon
                size="16"
                class="mr-1"
                icon="UserIcon"
              />
            </div>
          </b-col>
          <b-col>
            {{ branch.contact_name }}
            <!-- {{ branch.contact_name }} -->
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="auto">
            <div class="d-flex align-items-center">
              <feather-icon
                size="16"
                class="mr-1"
                icon="AtSignIcon"
              />
            </div>
          </b-col>
          <b-col>
            {{ branch.email }}
            <!-- {{ branch.email }} -->
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="auto">
            <div class="d-flex align-items-center">
              <feather-icon
                size="16"
                class="mr-1"
                icon="TruckIcon"
              />
            </div>
          </b-col>
          <b-col>
            {{ branch.logistic_name }}
            <!-- {{ $t(branch.active_status ? 'active' : 'unactive') }} -->
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="auto">
            <div class="d-flex align-items-center">
              <feather-icon
                size="16"
                class="mr-1"
                icon="PhoneIcon"
              />
            </div>
          </b-col>
          <b-col>
            {{ branch.phone }}
            <!-- Representante de Sucursal -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  components: {},

  props: {
    branch: {
      type: Object,
      default: () => {},
    },
    providerName: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      expressLoading: false,
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapGetters('app', ['currentBreakPoint', 'isSmallScreen']),
  },
  mounted() {},
  methods: {
    ...mapActions('branches', ['setBranchExpress']),
    async changeExpressStatus(value) {
      try {
        this.expressLoading = true
        await this.setBranchExpress({
          supplierId: this.$route.params.supplierId,
          branchId: this.$route.params.branchId,
          is_express: value,
        })
      } finally {
        this.expressLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#dropdown-1 {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
