<template>
  <div>
    <branch-card
      :branch="branch"
      :provider-name="selectedProvider"
    />
    <!-- <b-row>
      <b-col sm="12">
        <card-statistics-group
          icon="CpuIcon"
          statistic="86%"
          :statistic-title="$t('stats')"
        />
      </b-col>
    </b-row> -->
    <b-card
      no-body
      class="overflow-hidden"
    >
      <b-row>
        <b-col>
          <div id="root">
            <gmap-map
              ref="map"
              :center="center"
              :zoom="12"
              style="width: 100%; height: 650px"
            >
              <gmap-polygon
                v-if="paths.length > 0"
                ref="polygon"
                :paths="paths"
                :options="polygonOptions"
                @paths_changed="updateEdited($event)"
              />
            </gmap-map>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex'
// import CardStatisticsGroup from '@/components/cards/CardStatisticsGroup.vue'
import BranchCard from '@/components/branches/BranchCard.vue'

export default {
  components: {
    // CardStatisticsGroup,
    BranchCard,
  },
  data() {
    return {
      center: { lat: 25.673281129320067, lng: -100.3161126 },
      paths: [],
      polygonGeojson: '',
      polygonOptions: {
        fillColor: '#340668',
        fillOpacity: 0.45,
        strokeWeight: 0,
        strokeColor: '#999',
      },
    }
  },
  computed: {
    ...mapState('suppliers', ['selectedProvider']),
    ...mapGetters('branches', ['branch']),
    polygonPaths() {
      if (!this.mvcPaths) return null

      const paths = []
      for (let i = 0; i < this.mvcPaths.getLength(); i += 1) {
        const path = []
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j += 1) {
          const point = this.mvcPaths.getAt(i).getAt(j)
          path.push({ lat: point.lat(), lng: point.lng() })
        }
        paths.push(path)
      }
      // eslint-disable-next-line
      this.paths = paths
      return paths
    },
  },

  created() {
    this.fetchBranch({
      supplierId: this.$route.params.supplierId,
      branchId: this.$route.params.branchId,
    }).then(response => {
      this.readGeojson(response.geofence)
      const [[lng, lat]] = response.geofence.coordinates[0]
      this.center.lat = parseFloat(lat)
      this.center.lng = parseFloat(lng)
    })
  },

  destroyed() {
    this.clearProvider('')
  },

  methods: {
    ...mapMutations('suppliers', {
      selectProvider: 'SET_SELECTED_SUPPLIER',
      clearProvider: 'SET_SELECTED_SUPPLIER',
    }),
    ...mapActions('branches', ['fetchBranch']),
    readGeojson($event) {
      try {
        this.polygonGeojson = $event

        const v = $event

        this.paths = v.coordinates.map(linearRing => linearRing
          .slice(0, linearRing.length - 1)
          .map(([lng, lat]) => ({ lat, lng })))

        this.errorMessage = null
      } catch (err) {
        this.errorMessage = err.message
      }
    },
  },
}
</script>

<style></style>
