var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"rounded-5 h-100",attrs:{"id":("branch-" + (_vm.branch.id))}},[(_vm.isSmallScreen && _vm.currentBreakPoint !== 'md')?_c('b-dropdown',{staticClass:"z-top",attrs:{"id":"dropdown-1","toggle-class":"my-custom-toggle","size":"sm","right":"","no-caret":"","variant":"outline-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon","size":"18","color":"#000"}})]},proxy:true}],null,false,3784480924)},[_c('b-dropdown-item',{attrs:{"to":{
        name: 'editBranch',
        params: {
          supplierId: this.$route.params.supplierId,
          branchId: this.$route.params.branchId,
        },
      },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),_c('b-dropdown-item',{attrs:{"to":{
        name: 'shippings',
        params: {
          branchId: this.$route.params.branchId,
        },
      },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('shippings'))+" ")]),_c('b-dropdown-item',{attrs:{"variant":"primary","to":{
        name: 'supplierProductsCatalogs',
        params: { id: _vm.branch.id, type: 'sucursal' },
      }}},[_vm._v(" "+_vm._s(_vm.$t('ProductCatalogs'))+" ")]),(
        _vm.currentUser.role_name === 'admin' ||
          _vm.currentUser.role_name === 'supplier_admin'
      )?_c('b-dropdown-item',{attrs:{"variant":"outline-primary","to":{ name: 'supplierQuotes', params: { id: _vm.branch.id } }}},[_vm._v(" "+_vm._s(_vm.$t('biddings'))+" ")]):_vm._e()],1):_vm._e(),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2"},[_c('b-img',{attrs:{"fluid":"","width":"70px","src":require('@/assets/images/logo/logo.png')}})],1),_c('div',{staticClass:"w-75"},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"mb-0 mr-1 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.providerName)+" "+_vm._s(_vm.$t('branch'))+" "+_vm._s(_vm.branch.name)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-1 text-muted"},[_vm._v(" "+_vm._s(_vm.$t('deliveryTime'))+" ")]),_c('b-overlay',{attrs:{"show":_vm.expressLoading}},[_c('b-form-radio-group',{attrs:{"id":"btn-radios-1","checked":_vm.branch.is_express,"size":"sm","name":"radios-btn-default","buttons":""},on:{"change":_vm.changeExpressStatus}},[_c('b-form-radio',{attrs:{"button-variant":"outline-primary","value":true}},[_vm._v(" "+_vm._s(_vm.$t('express'))+" ")]),_c('b-form-radio',{attrs:{"button-variant":"outline-secondary","value":false}},[_vm._v(" Normal ")])],1)],1)],1)]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.branch.email))]),(!_vm.isSmallScreen || _vm.currentBreakPoint === 'md')?_c('div',{staticClass:"d-flex mt-1"},[_c('b-button',{staticClass:"mr-1 d-flex align-items-center",attrs:{"size":"sm","to":{
                name: 'editBranch',
                params: {
                  supplierId: this.$route.params.supplierId,
                  branchId: this.$route.params.branchId,
                },
              },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),_c('b-button',{staticClass:"mr-1 d-flex align-items-center",attrs:{"size":"sm","to":{
                name: 'shippings',
                params: {
                  branchId: this.$route.params.branchId,
                },
              },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('shippings'))+" ")]),_c('b-button',{staticClass:"mr-1 d-flex align-items-center",attrs:{"size":"sm","variant":"primary","to":{
                name: 'supplierProductsCatalogs',
                params: { id: _vm.branch.id, type: 'sucursal' },
              }}},[_vm._v(" "+_vm._s(_vm.$t('ProductCatalogs'))+" ")]),(
                _vm.currentUser.role_name === 'admin' ||
                  _vm.currentUser.role_name === 'supplier_admin'
              )?_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"size":"sm","variant":"outline-primary","to":{ name: 'supplierQuotes', params: { id: _vm.branch.id } }}},[_vm._v(" "+_vm._s(_vm.$t('biddings'))+" ")]):_vm._e()],1):_vm._e()])])]),_c('b-col',{staticClass:"mt-1 mt-lg-0",attrs:{"md":"auto"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"16","icon":"UserIcon"}})],1)]),_c('b-col',[_vm._v(" "+_vm._s(_vm.branch.contact_name)+" ")])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"16","icon":"AtSignIcon"}})],1)]),_c('b-col',[_vm._v(" "+_vm._s(_vm.branch.email)+" ")])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"16","icon":"TruckIcon"}})],1)]),_c('b-col',[_vm._v(" "+_vm._s(_vm.branch.logistic_name)+" ")])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"16","icon":"PhoneIcon"}})],1)]),_c('b-col',[_vm._v(" "+_vm._s(_vm.branch.phone)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }